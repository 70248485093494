import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

import LogicFlow from '@logicflow/core';
import '@logicflow/core/dist/style/index.css';
import { DndPanel, SelectionSelect, Menu, NodeResize, Snapshot } from '@logicflow/extension';
import '@logicflow/extension/lib/style/index.css'


import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import router from './router'
import ajax from "@/ajax";
import { storeInit } from '@/store/index.js'
import VueParticles from 'vue-particles'

//import '@/assets/css/base.scss'
import '@/assets/css/public-class.scss'
import '@/assets/css/iconfont.css'


var app = createApp(App)

app.use(ElementPlus)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
app.use(createPinia())
app.use(router)
app.use(VueParticles)

LogicFlow.use(DndPanel);
LogicFlow.use(SelectionSelect);
LogicFlow.use(Menu);
LogicFlow.use(NodeResize);
LogicFlow.use(Snapshot);

ajax.store = storeInit()
app.config.globalProperties._ajax = ajax
app.config.globalProperties.LogicFlow = LogicFlow

app.config.globalProperties._store = ajax.store
app.config.globalProperties.numberBlur = (event, set) => {
    var k = /\d*\.?\d*%?/ 
    set.form[set.key] = (event.target.value + "").match(k)[0]
}
app.config.globalProperties.routerReplace = function(name, data) {
    if (!name) {
        return
    }
    this.$router.replace({
        name: name,
        query: data
    });
}

app.config.globalProperties.routerPush = function(name, data) {
    if (!name) {
        return
    }
    this.$router.push({
        name: name,
        query: data
    });
}

app.config.globalProperties.routerResolve = function(name, data) {
    if (!name) {
        return
    }
    let routeUrl = this.$router.resolve({
        path: "/" + name,
        query: data,
    });
    window.open(routeUrl.href, "_blank");
}

app.config.globalProperties.openFun = function(link) {
    if (!link) {
        return
    }
    window.open(link)
}

app.mount('#app')