<!-- @format -->

<template>
    <el-config-provider :locale="zhCn">
        <router-view v-if="isRouterAlive" />
    </el-config-provider>
</template>
<script>
import zhCn from "element-plus/lib/locale/lang/zh-cn";
import { storeInit } from "@/store/index.js";
import { test, formal } from "@/config/index";

export default {
    name: "app",
    provide() {
        return {
            reload: this.reload,
        };
    },
    data() {
        return {
            zhCn,
            form: {},
            isRouterAlive: true,
            store: storeInit(),
        };
    },
    mounted() {
        // 解决路由参数和URL挂载问题
        // var url_obj = this._ajax.get_url_argument_obj()
        // if (url_obj.loginnum) {
        //     this.store.user.ID = url_obj.loginnum
        //     this.store.user.account = url_obj.loginnum
        //     this.store.user.name = url_obj.name
        //     this.store.user.school = url_obj.school
        //     localStorage.user = JSON.stringify(this.store.user)
        //     var date = new Date()
        //     var m = date.getMonth() + 1
        //     var d = date.getDate()
        //     localStorage.date = date.getFullYear() + "/" + (m < 10 ? "0" + m : m) + "/" + (d < 10 ? "0" + d : d)
        // }

        this._ajax.app = this;
        // if (location.pathname != "/" && !test) {
        //     this._ajax.post({
        //         url: "GetUserInfo",
        //         data: {},
        //         success: (res) => {
        //             // console.log("res:", res);
        //             this._ajax.store.Header = res.Results;
        //         },
        //     });
        // }
        // this.updateFun();
        // this.getMessageFun();
        // setInterval(() => {
        //     this.updateFun();
        // }, 1000 * 60 * 5);
        // setInterval(() => {
        //     this.getMessageFun();
        // }, 1000 * 60 * 30);
    },
    methods: {
        // updateFun() {
        //     this._ajax.post({
        //         url: "GetUpdate",
        //         data: {},
        //         success: (res) => {
        //             // console.log("res:", res)
        //             if (res.Results) {
        //                 this.$notify({
        //                     type: "warning",
        //                     title: "更新提示",
        //                     message: res.Results,
        //                     duration: 0,
        //                 });
        //             }
        //         },
        //     });
        // },
        // getMessageFun() {
        //     this._ajax.post({
        //         url: "GetMessage",
        //         data: {},
        //         success: (res) => {
        //             // console.log("res:", res.data)
        //             if (res.Results) {
        //             let html = res.Results
        //                 this.$notify({
        //                     type: "warning",
        //                     // grouping: true,
        //                     title: "新消息提示",
        //                     dangerouslyUseHTMLString: true,
        //                     message: html,
        //                     duration: 0,
        //                 });
        //             }
        //         }
        //     });
        // },
        // sub() {
        //     // console.log(this.form)
        //     localStorage.HeaderKey = this.form
        //     this.$router.push({
        //         name: "news_list",
        //     });
        // },
        reload() {
            this.isRouterAlive = false;
            this.$nextTick(() => {
                this.isRouterAlive = true;
            });
        },
    },
};
</script>
