/** @format */

import { createRouter, createWebHistory } from "vue-router";
import { routes } from "./router";
import { test, formal } from "@/config/index";

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    // mode:'history',
    routes,
    scrollBehavior(to, from, savedPosition) {
        return { x: 0, y: 0 };
    },
});
router.afterEach(() => {
    window.scrollTo(0,0)
})
if (test) {
} else {
    /*router.beforeEach((to) => {
        const user = JSON.parse(localStorage.Header || "{}")
        if (to.name !== 'login' && !user.Guid) {
            router.replace({ name: 'login' })
            return false
        } else {
            return true
        }
    })*/
}

export default router;
